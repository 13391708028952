<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Subject Marks
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title pb-0">
              <v-flex xs3 sm4>
                <v-select
                  :items="exams"
                  class="pa-0"
                  label="Exam"
                  v-model="exam"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs3 sm4>
                <v-select
                  :loading="gradeLoading"
                  :disabled="!exam"
                  :items="grades"
                  persistent-hint
                  :hint="selectedEvaluation"
                  class="pa-0"
                  label="Grade"
                  v-model="grade"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs2 style="margin-bottom:24px" v-if="$auth.isAdmin() && grade && form.is_update">
                <v-btn
                  outlined
                  color="indigo"
                  @click="getPerviousunMarkedSubject()"
                >
                  Pull Subject
                </v-btn>
              </v-flex>
            </v-card-title>
          </v-card>
          <v-card-title>
            <alert-message
              v-if="is_publish && !examPublishAlert"
              type="error"
              title="Exam published"
              message="Exam has already been published and hence any sort of data modification is strictly prohibited."
            ></alert-message>
          </v-card-title>
          <div class="overlay attendance">
            <div
              style="float: right;display: block;margin-top: -20px;"
              class="data-represent"
            >
              <div>
                <span class="d-success"></span>&nbsp;
                <strong>{{ selectedEvaluation }}</strong>
                <!--<small> Students</small>-->
              </div>
            </div>

            <div class="clearfix"></div>

            <v-data-table
              :headers="advance_practical_mode ? advanceheaders:headers"
              hide-default-footer
              :options.sync="pagination"
              :items="form.items.data"
              footer-props.items-per-page-options="rowsPerPageItems"
              :loading="form.loading"
            >
              <template v-slot:item="{ index, item }">
                <tr>
                  <td>{{ index + form.items.meta.from }}</td>
                  <td class="text-xs-left">
                    <strong>
                      {{ item.subject.name }}
                      <!--<span style="color: #777;margin-left: 10px;font-size: 12px;">({{item.subject.final_fullmark || '-'}})</span>-->
                    </strong>
                    <small style="color:#999;">
                      <strong
                        >{{
                          item.subject.sub_type === "opt" ? "(Optional)" : ""
                        }}
                      </strong>
                    </small>
                    <br />
                    <small v-if="item.theory || item.practical">
                      <span
                        :class="
                          totalSubjectFlag(
                            form.items.data[index].theory,
                            form.items.data[index].practical
                          )
                            ? 'highlights'
                            : ''
                        "
                        >Total Mark -
                        <strong>
                          {{
                            total(
                              form.items.data[index].theory,
                              form.items.data[index].practical
                            )
                          }}</strong
                        >
                      </span>
                    </small>
                  </td>
                  <td class="text-right">
                    <input
                      type="number"
                      @focus="$event.target.select()"
                      :autofocus="index === 0"
                      @change="changed = true"
                      v-if="
                        $auth.can('subject-mark-update&subject-mark-create')
                      "
                      class="marks_entry"
                      autocomplete="off"
                      :class="
                        totalSubjectFlag(
                          form.items.data[index].theory,
                          form.items.data[index].practical
                        )
                          ? 'borderRed'
                          : ''
                      "
                      v-model="form.items.data[index].theory"
                      :disabled="is_publish"
                    />
                  </td>
                  <td class="text-right">
                    <input
                      @click="advance_practical_mode ? setData(item,index) :''"
                      @change="changed = true"
                      @focus="$event.target.select()"
                      v-if="
                        $auth.can('subject-mark-update&subject-mark-create')
                      "
                      autocomplete="off"
                      class="marks_entry"
                      :class="
                        totalSubjectFlag(
                          form.items.data[index].theory,
                          form.items.data[index].practical
                        )
                          ? 'borderRed'
                          : ''
                      "
                      v-model="form.items.data[index].practical"
                      :disabled="is_publish"
                      type="number"
                    />
                  </td>
                  <td class="text-right" v-if="!is_final && !is_publish && item.id !== null">
                    <delete-button
                    :permission="true"
                    @agree="deleteRoutine(item)"
                  />
                  </td>
                </tr>
              </template>

              <v-alert
                slot="no-results"
                :value="true"
                color="error"
                icon="warning"
              >
                Your search for "{{ search }}" found no results.
              </v-alert>
            </v-data-table>

            <br />
            <v-container
              v-if="is_publish === false && !examPublishAlert"
              style="margin-top: -20px"
            >
              <alert-message
                v-if="!dataSubmit"
                extraSmall
                size="small"
                type="warning"
                title="Subject Mark Alert"
                message="Before saving subject's mark, please make sure you have updated and verified all the subject that are and aren't listed for exams, Subject mark must not be greater than final full mark defined for subject"
              ></alert-message>
              <alert-message
                v-if="dataSubmit && form.items.data && form.items.data.length"
                extraSmall
                size="small"
                type="error"
                title="Please review your marks"
                message="Theory + Practical should be exceed 100, Marks should not be 0 or empty Practical should not be greater than theory"
              >
              </alert-message>
              <alert-message
                v-if="theroy_marks_not_set"
                extraSmall
                size="small"
                type="error"
                title="Internal Mark Alert"
                message="Please save External Marks first to enable Internal Marks setup"
              ></alert-message>
            </v-container>

            <v-card-title v-if="form.items.data.length">
              <v-btn
                :loading="saving"
                :outlined="form.is_update"
                :color="form.is_update ? 'warning' : 'primary'"
                style="margin-bottom: 5px"
                v-if="
                  $auth.can('subject-mark-update&subject-mark-create') &&
                    is_publish === false
                "
                @click="save"
                block
                large
                :disabled="form.items.data.length < 1 || !changed || saving"
              >
                {{ form.is_update ? "Update" : "Save" }}
              </v-btn>
            </v-card-title>

            <div
              :class="'inner-overlay ' + (examPublishAlert ? '' : 'dont-show')"
            >
              <p>
                <v-icon style="font-size: 50px;color:red;">warning</v-icon>
                <br />
                Exam has already been published. <br />
                <span style="font-size: 15px;"
                  ><a href="" @click.prevent="examPublishAlert = false"
                    >Click Here</a
                  >
                  to view the marks.</span
                >
              </p>
            </div>
          </div>
        </v-card>
      </v-flex>
      <v-dialog v-model="form.showDialog" persistent max-width="600px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Add</span>
          </v-card-title>
          <v-card-text class="pb-1 pt-4">
            <v-select
                  v-model="selectedSubject"
                  :items="filteredSubjects"
                  label="Select Subject"
                  dense
                  outlined
                  multiple
                />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                text
                @click="
                  (form.showDialog = false),
                    form.reset(),
                    $refs.form ? $refs.form.reset() : ''
                "
                >Close</v-btn
              >
              <v-btn color="success" text @click="saveUnmarkedSubjectMarks" :disabled="selectedSubject.length == 0">Save</v-btn>
          </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="advancePracticalDiaglog" persistent max-width="800px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Set Continuous Assessment System (CAS) for {{ selected_subject_name }}</span>
          </v-card-title>
          <v-card-text class="pb-1 pt-4">
            <v-data-table
            hide-default-footer
            :headers="advancePracticalHeader"
            :items="practical_head_list"
            :server-items-length="advancePracticalHeader.length"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>
                  <v-checkbox
                      :disabled="(item.savedPracticalHead)? true:false" 
                      v-model="item.is_selected"
                      primary
                      hide-details
                      @change="detechChange()"
                    >
                    </v-checkbox>
                </td>
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">{{ item.title }}</td>
                <td class="text-right">
                  <!-- {{ item.is_selected }} -->
                  <input
                      :disabled="item.is_selected === true ? false:true" 
                      v-model="item.marks"
                      autocomplete="off"
                      class="marks_entry"
                      type="number"
                      :key="refreshKey"
                  >
                </td>
                <td class="text-right">
                  <delete-button
                    :disabled="(item.is_selected === true && item.marks !== 0)? false:true" 
                    :permission="true"
                    @agree="deleteSubjectMark(item,index)"
                  />
                </td>
              </tr>
            </template>
          </v-data-table>
          </v-card-text>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                outlined
                @click="resetAdvancePracticalForm()"
                >Close</v-btn
              >
              <load-button
                @action="savepracticalHeadWithMarks()"
                :loader="loader"
                color="success"
                text
                >Save</load-button
              >
              <!-- <v-btn outlined color="success" text @click="savepracticalHeadWithMarks()">Save</v-btn> -->
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";

export default {
  mixins: [Mixins],
  data: () => ({
    changed: false,
    saving: false,
    selectedSubject:[],
    filteredSubjects:[],
    form: new Form(
      {
        showDialog:false,
        subject_marks: [],
        grade_id: "",
        exam_id: 0,
        is_update: false,
      },
      "/api/mark-subject"
    ),
    advancePracticalForm: new Form(
      {
        
      },"/api/practical-head-mark"
    ),
    search: null,
    pagination: {
      itemsPerPage: 20,
      rowsPerPage: 20,
    },
    examPublishAlert: false,
    rowsPerPageItems: [5, 10, 15, 20],
    gradeLoading: false,
    subject: "",
    exam: "",
    exams: [],
    grade: "",
    grades: [],
    is_publish: false,
    is_final: false,
    total_marks: 0,
    dataSubmit: false,
    headers: [
      { text: "SN", align: "center", value: "id", width: 50, sortable: false },
      { text: "Subject", align: "left", value: "subject", sortable: false },
      {
        text: "Theory",
        align: "right",
        value: "exam_date",
        sortable: false,
        width: 150,
      },
      {
        text: "Practical",
        align: "right",
        value: "start_time",
        sortable: false,
        width: 150,
      },
       {
        text: "Action",
        align: "centre",
        value: "end_time",
        sortable: false,
        width: 50,
      },

    ],
    advanceheaders: [
      { text: "SN", align: "center", value: "id", width: 50, sortable: false },
      { text: "Subject", align: "left", value: "subject", sortable: false },
      {
        text: "External",
        align: "right",
        value: "external",
        sortable: false,
        width: 150,
      },
      {
        text: "Internal",
        align: "right",
        value: "internal",
        sortable: false,
        width: 150,
      },
    
       {
        text: "Action",
        align: "centre",
        value: "end_time",
        sortable: false,
        width: 50,
      },

    ],
    advance_practical_mode:false,
    advancePracticalDiaglog:false,
    advancePracticalHeader:[

      { text: "", align: "left", value: "id", width: 1, sortable: false },
      { text: "SN", align: "left", value: "id", width: 1, sortable: false },
      { text: "Title", align: "left", value: "title", width: 180, sortable: false },
      { text: "Marks", align: "right", value: "mark", width: 20, sortable: false },
      { text: "Action", align: "right", width: 20, sortable: false },
    ],
    selected_subject_id:'',
    selected_subject_name:'',
    selected_array_index:'',
    practical_head_list:[],
    refreshKey:0,
    loader:false,
    theroy_marks_not_set: false,

  }),
  computed: {
    ...mapState(["batch"]),
    selectedEvaluation() {
      let grade = this.grade;
      let output = "";
      this.grades.map(function(item) {
        if (grade === item.value && item.evaluation) {
          output = "Evaluation " + item.evaluation + "%";
        }
      });
      return output;
    },

  },
  mounted() {
    this.getExams();
    this.getExamSetting();
  },
  watch: {
    batch: function(value) {
      this.get();
    },
    grade: function(value) {
      this.form.items.data = [];
      if (value) {
        this.get();
        this.getPracticalHeads();

      }
    },
    exam: function() {
      this.getGrades();
      this.grade = "";
    },
  },

  methods: {
  
    saveUnmarkedSubjectMarks(){
      if(this.selectedSubject.length > 0){
        this.$rest.post('/api/save-ummarked-subject-marks',{
          subjects:this.selectedSubject,
          grade_id:this.grade,
          exam_id:this.exam
        })
        .then((response)=>{
          if(response.status == 200){
            this.form.showDialog = false;
            this.get()
            this.$events.fire("notification",{
              message: res.data.message,
              status: "success",
            });
          }
        })
        .catch((error)=>{
          if (error.response)
              this.$events.fire("notification", {
              message: error.response.data.message,
              status: "error",
          });
        })
  
      }else{
        this.$events.fire("notification", {
          message: 'Select At least one subject',
          status: "error",
        });
      }
    },
    getPerviousunMarkedSubject(){
      this.getAllSubjects(null,this.grade);
    },
    

    get(params) {
      if (this.exam) {
        let extraParams =
          "batchId=" +
          this.batch.id +
          "&gradeId=" +
          this.grade +
          "&examId=" +
          this.exam;
        let query = [null, undefined].includes(params)
          ? this.queryString(extraParams)
          : params;
        this.form.get(null, query).then(({ data }) => {
          this.form.is_update = data.is_update;
          this.pagination.totalItems = data.meta.total;
          this.is_publish = data.isPublished;
          this.examPublishAlert = data.isPublished;
          this.total();
          this.changed = false;
        });
      }
    },

    save() {
      this.form.grade_id = this.grade;
      this.form.exam_id = this.exam;
      this.form.subject_marks = this.form.items.data;
      this.form.fireFetch = false;
      this.saving = true;
      this.form
        .store()
        .then(() => {
          this.form.is_update = true;
          this.changed = false;
        })
        .finally(() => {
          this.saving = false;
          this.get();
          this.theroy_marks_not_set = false;
        });
    },
    getGrades() {
      this.gradeLoading = true;
      this.$rest
        .get(
          "/api/exam-grade?rowsPerPage=25&sortBy=rank&descending=true&slim=true&examId=" +
            this.exam
        )
        .then(({ data }) => {
          this.grades = data.data.map((item) => {
            return {
              value: item.grade_id,
              text: item.name,
              evaluation: item.evaluation,
            };
          });
        })
        .finally(() => {
          this.gradeLoading = false;
        });
    },
    getExams() {
      this.$rest.get("/api/exam").then(({ data }) => {
        this.exams = data.data.map((item) => {
          return { value: item.id, text: item.name };
        });
      });
    },
    editData(subject) {
      this.subject = subject;
    },

    total(tmark, pmark) {
      let count = 0;
      if (this.form.items.data) count = this.form.items.data.length;
      let j = 0;
      this.form.items.data.map((res) => {
        if (
          parseFloat(res.practical || 0) + parseFloat(res.theory || 0) <=
            parseFloat(100) &&
          parseFloat(res.theory || 0) >= parseFloat(res.practical || 0) &&
          (res.theory !== null || res.practical !== null) &&
          parseFloat(res.practical || 0) + parseFloat(res.theory || 0) <=
            parseFloat(res.subject.final_fullmark || 0)
        ) {
          j++;
        } else {
        }
      });

      // this.dataSubmit = j !== count;
      if ([undefined, "", null, NaN].includes(tmark)) tmark = 0;
      if ([undefined, "", null, NaN].includes(pmark)) pmark = 0;
      return parseFloat(tmark) + parseFloat(pmark);
    },
    totalSubjectFlag(tmark, pmark) {
      if (
        ![undefined, "", null].includes(tmark) ||
        ![undefined, "", null].includes(pmark)
      ) {
        if (parseFloat(pmark) + parseFloat(tmark) > 100) return true;
      }
      return false;
    },
    deleteRoutine(item){
       this.$rest
        .delete("/api/mark-subject/" + item.id, {
          data: {
            exam_id: this.exam,
            grade_id: this.grade,
            subject_id: item.subject_id,
          },
        })
        .then((res) => {
          if (res.status == 200)
            this.$events.fire("notification", {
              message: res.data.message,
              status: "success",
            });
            this.get();
        })
        .catch((e) => {
          if (e.response)
            this.$events.fire("notification", {
              message: e.response.data.message,
              status: "error",
            });
        });
    },
    getExamSetting() {
      this.$rest.get("/api/exam-setting").then(({ data }) => {
        this.advance_practical_mode = data.exam
          ? data.exam.advance_practical_mode === "true"
          : false;
       
      });
    },
    setData(item,index){
      this.mark_subject_id = item.id;
      this.selected_subject_id = item.subject_id;
      this.selected_subject_name = item.subject.name;
      this.selected_array_index = index
      this.advancePracticalDiaglog= item.id ? true : false;
      this.theroy_marks_not_set = item.id ? false :true;
      if(!item.id) return '';
      let practicalHeadMark = [];
      this.$rest.get('/api/practical-head-mark?'+"mark_subject_id="+item.id)
        .then((res)=>{
        practicalHeadMark = res.data.data;
        let filterd_data = this.practical_head_list.map((item)=>{
          const savedPracticalHead = practicalHeadMark.find((head)=>head.practical_head_id === item.id)
          item.savedPracticalHead = savedPracticalHead;
          item.is_selected = !!savedPracticalHead;
          item.marks = !!savedPracticalHead === true ? savedPracticalHead.marks:0
          return item;
         
        })
        this.practical_head_list = filterd_data;

      })

     
    },

    getPracticalHeads(){
      if(this.advance_practical_mode){
        this.$rest.get('/api/practical-head').then((res)=>{
          this.practical_head_list = res.data.data
        })
      }
    },
  
    resetAdvancePracticalForm(){
      this.advancePracticalDiaglog = false;
      this.selected_subject_id = '';
      this.selected_subject_name = '';
      this.selected_array_index  = '';
    },
    savepracticalHeadWithMarks(){
      this.loader = true;
      let data = this.practical_head_list.filter((item)=>{
        if(item.is_selected) return item
      });
      var total = data.reduce(function(prev, cur) {
        return parseInt(prev) + parseInt(cur.marks);
      }, 0);
      this.form.items.data[this.selected_array_index].practical = total
      this.$rest.post('/api/practical-head-mark',{
        data:data,
        subject_id:this.selected_subject_id,
        exam_id:this.exam,
        mark_subject_id:this.mark_subject_id
      })
      .then((res)=>{
        if(res.status == 200){
          this.$events.fire("notification", {
              message: res.data.message,
              status: "success",
          });
          this.changed = true;
        }
      }).catch((err)=>{
        let slectedIndex = this.selected_array_index;
        this.form.items.data[slectedIndex].practical = '';
        if (err.response)
          this.$events.fire("notification", {
            message: err.response.data.message,
            status: "error",
          });
      }).finally((f)=>{
        this.loader = false
        this.resetAdvancePracticalForm()
      })
    },
    detechChange(){
      this.refreshKey = this.refreshKey +1;
    },
    deleteSubjectMark(item,index){
      let practicalHeadId = item.savedPracticalHead.id;
      this.practical_head_list[index].marks = 0;
      this.practical_head_list[index].is_selected = false;
      this.refreshKey = this.refreshKey +1;


      let data = this.practical_head_list.filter((item)=>{
        if(item.is_selected) return item
      });
      var total = data.reduce(function(prev, cur) {
        return parseInt(prev) + parseInt(cur.marks);
      }, 0);
      this.form.items.data[this.selected_array_index].practical = total
      this.$rest.delete('/api/practical-head-mark/'+practicalHeadId)
      .then((res)=>{
        if(res.status == 200){
          this.$events.fire("notification", {
              message: res.data.message,
              status: "success",
          });
          this.changed = true;

        }
      }).catch((err)=>{
        if (err.response)
          this.$events.fire("notification", {
            message: err.response.data.message,
            status: "error",
          });
      }).finally((f)=>{
        this.get();
        // this.getPracticalHeads();
        // console.log(item,index,'delete');
        // this.setData(item,index)
      })

    }
   
  },
  
 
};
</script>
<style lang="scss" scoped>
.marks_entry {
  border: 1px solid #ccc;
  font-weight: bold;
  border-radius: 3px;
  width: 60px;
  height: 25px !important;
  text-align: center !important;
}

input[disabled] {
  background-color: #eee;
  color: #ccc;
  cursor: not-allowed;
}

.highlights {
  background: red;
  padding-right: 5px;
  padding-left: 5px;
  border-radius: 8px;
  /*color: black;*/
  color: beige;
}

input:focus {
  outline: none;
}

.borderRed {
  border: 1px solid #f83f37;
  color: red;
}

.attendance {
  .inner-overlay p {
    margin-top: 3%;
  }
}

td .v-input--selection-controls{
  margin-top: 0px!important;
}

</style>
